import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Послуги автосервісу Автомийка на Макарова
			</title>
			<meta name={"description"} content={"Де ваш автомобіль отримує королівське ставлення"} />
			<meta property={"og:title"} content={"Наші послуги | Послуги автосервісу Автомийка на Макарова"} />
			<meta property={"og:description"} content={"У Автомийка на Макарова ми пропонуємо комплексний набір послуг, розроблений для задоволення всіх аспектів естетичних потреб вашого автомобіля та його збереження."} />
			<meta property={"og:image"} content={"https://jedis-ua.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jedis-ua.com/img/88.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jedis-ua.com/img/88.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jedis-ua.com/img/88.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jedis-ua.com/img/88.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://jedis-ua.com/img/88.png"} />
			<meta name={"msapplication-TileImage"} content={"https://jedis-ua.com/img/88.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 120px 0" background="linear-gradient(180deg,rgba(4, 3, 30, 0.9) 1%,rgba(5, 15, 49, 0.89) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://jedis-ua.com/img/4.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Headline-2">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text margin="0px 0px 20px 0px" font="normal 700 52px/1.2 --fontFamily-sans" color="--light" sm-font="normal 700 42px/1.2 --fontFamily-sans">
				Наш спектр послуг
			</Text>
			<Text
				margin="0px 180px 80px 0px"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#aab1b9"
				lg-margin="0px 0 50px 0px"
				md-margin="0px 0 50px 0px"
			>
				У Автомийка на Макарова ми пропонуємо комплексний набір послуг, розроблений для задоволення всіх аспектів естетичних потреб вашого автомобіля та його збереження. Наші фахівці забезпечать високоякісний догляд, гарантуючи, що ваш автомобіль буде виглядати якнайкраще.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Найкраща мийка
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Наша фірмова мийка використовує високоякісні, екологічно чисті засоби, щоб забезпечити ретельне очищення, яке дбайливо ставиться до лакофарбового покриття вашого автомобіля.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Оздоблення салону
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Від обробки шкіри до чищення килимів - наші послуги з догляду за інтер'єром омолоджують салон вашого автомобіля, роблячи його свіжим і бездоганним.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Догляд за екстер'єром
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Ми пропонуємо полірування, нанесення воску та керамічних покриттів, щоб захистити фарбу вашого автомобіля та забезпечити його блискучий вигляд.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Очищення моторного відсіку
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Чистий моторний відсік може покращити продуктивність та довговічність. Наше детальне очищення запобігає накопиченню нагару та підтримує ваш двигун в ідеальному стані.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Реставрація фар
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							З часом фари можуть помутніти. Наша послуга з реставрації повертає їм чіткість, покращуючи як зовнішній вигляд, так і безпеку.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
							Індивідуальні пакети
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
							Ми розуміємо, що кожен автомобіль має унікальні потреби. Наші індивідуальні пакети послуг дозволять вам вибрати послуги, які найкраще відповідають вимогам вашого автомобіля.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});